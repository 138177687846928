import React from "react"
import { Link } from "gatsby"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const Foot = () => {
  return (
    <>
      <hr />
      <Row>
        <Col>
          <Row>
            <Col md={12} lg="auto"><Link style={{fontSize: "11pt", color: "#536eb1", textDecoration: "none"}} to="/our-story">About</Link></Col>
            <Col md={12} lg="auto"><Link style={{fontSize: "11pt", color: "#536eb1", textDecoration: "none"}} to="/our-locations">Locations</Link></Col>
            <Col md={12} lg="auto"><Link style={{fontSize: "11pt", color: "#536eb1", textDecoration: "none"}} to="/our-services">Services</Link></Col>
            <Col md={12} lg="auto"><Link style={{fontSize: "11pt", color: "#536eb1", textDecoration: "none"}} to="/client-testimonials">Testimonials</Link></Col>
          </Row>
        </Col>
        <Col>
          <h6 className="mt-1 mb-0 pt-0 text-end">Contact Us Today!</h6>
          <div className="text-end"><Link className="mt-0 pt-0" style={{fontSize: "10pt", color: "#536eb1", textDecoration: "none"}} to="/contact-us">Send a message &rarr;</Link></div>
        </Col>
      </Row>
    </>
  ) 
}

export default Foot