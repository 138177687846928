import React from "react"
import { Helmet } from "react-helmet"

import LogoWhite from "../images/icons/logo_white.jpg"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Foot from "../components/foot"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const OurServices = () => (
  <Layout>
    <SEO title="Our Services" />
    <Helmet>
      <meta property="og:image" content={ LogoWhite } />
    </Helmet>
    <Container className="mt-4">
      <h3>Our Services</h3>

      <Row className="mt-3" style={{fontSize: "12pt"}}>
        <Col xs={12} md>
          <ul className="ps-4 ms-0">
            <li>Personalized care</li>
            <li>Compassionate and loving caregiving staff</li>
            <li>24/7 awake staff</li>
            <li>Daily vitals recording and monitoring</li>
            <li>Fresh and nutritious food made from scratch</li>
            <li>Special diet</li>
            <li>Medication management</li>
            <li>Hospice waiver</li>
            <li>Respite stay</li>
            <li>Wound care support</li>
            <li>Memory care</li>
            <li>Assistance with incidental medical care</li>
          </ul>
        </Col>
        <Col xs={12} md>
          <ul className="ps-4 ms-0">
            <li>Home health support</li>
            <li>Oxygen administration</li>
            <li>Mobility assistance</li>
            <li>Assistance with ADLs</li>
            <li>Hoyer lift</li>
            <li>Lateral transport chair</li>
            <li>Daily exercises including physical therapy home exercises and walking</li>
            <li>Housekeeping and laundry</li>
            <li>Birthday celebrations</li>
            <li>Haircut and style</li>
            <li>Monthly worship service</li>
          </ul>
        </Col>
      </Row>

      <div style={{marginTop: "74px"}}>
        <Foot />
      </div>
    </Container>
  </Layout>
)

export default OurServices